<template>
	<div>
		<div class="queryBox mb20 ">
			<div class="mb20">
				<el-button size="mini" type="primary" @click="newStore('new')" class="mr20">新增</el-button>
				<span class="mr20">共{{payTypeInfoData.total_count}}个</span>
				<span class="mr20">可用{{payTypeInfoData.enable_count}}个</span>
				<span class="mr20">已支付{{payTypeInfoData.pay_count}}个</span>

			</div>
			<div class="flexX flexcenter">
				<span>状态：</span>
				<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择状态">
					<el-option v-for="(item,index) in stateArr" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
			
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"
					@click="getData" :disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" border="" stripe style="width: 100%" v-loading="loading"
				size="mini" :show-overflow-tooltip="true">
				<el-table-column type="index" label="序号" align="center" width="120px"></el-table-column>
				<el-table-column prop="store_name" label="店铺名称" align="center"></el-table-column>			
				<el-table-column label="启停状态" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.state == 1?'csuccess':'cdanger'">{{scope.row.state == 1?'启用':'停用'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="添加时间" align="center"></el-table-column>	
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-link type="primary" class="mr20" @click="edit(scope.row)">编辑</el-link>
						<el-link type="primary" class="mr20" @click="showInfo(scope.row)">码详情</el-link>
						<el-link type="primary" class="mr20" @click="gotopage('id',scope.row.id)">收款码</el-link>
						
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" title="" :visible.sync="dialogVisible" @close="close" :width="dialogType =='qr'?'50%':'30%'"
		 :close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">
				<div class="textCenter" v-if="dialogType == 'new'||dialogType == 'show'">
					<div class="flexX flexcenter mb20">
						<span class="dataKey">店铺名称：</span>
						<el-input size="mini" placeholder="请输入店铺名称" style="width: 220px;" v-model="storeInfo.store_name"></el-input>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">启停状态：</span>
						<el-select size="mini" class="mr20" v-model="storeInfo.state" placeholder="请选择状态">
							<el-option v-if="index!=0" v-for="(item,index) in stateArr" :key="item.key" :label="item.val" :value="item.key"></el-option>
						</el-select>
					</div>
				</div>
				<div v-if="dialogType =='qr'" class="mb20">
					<el-table :max-height="height" :data="storeInfo.groupMoney" border="" stripe style="width: 100%" v-loading="loading"
						size="mini" :show-overflow-tooltip="true">
						<el-table-column type="index" label="序号" align="center" width="120px"></el-table-column>
						<el-table-column prop="money" label="金额" align="center">
							<template slot-scope="scope">
								<span class="cprimary block pointer" @click="gotopage('money',scope.row.money)">{{scope.row.money}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="total_count" label="总码数" align="center">
							<template slot-scope="scope">
								<span class="cprimary block pointer" @click="gotopage('money',scope.row.money)">{{scope.row.total_count}}</span>
							</template>
						</el-table-column>				
						<el-table-column label="可用数量" align="center">
							<template slot-scope="scope">
								<span class="cprimary block pointer" @click="gotopage('state',1,'money',scope.row.money)">{{scope.row.qr_enable_count}}</span>
							</template>
						</el-table-column>		
						<el-table-column label="自动停码数" align="center">
							<template slot-scope="scope">
								<span class="cprimary block pointer" @click="gotopage('state',3,'money',scope.row.money)">{{scope.row.qr_disable_count}}</span>
							</template>
						</el-table-column>
						<el-table-column label="总金额" align="center">
							<template slot-scope="scope">
								<span class="">{{scope.row.total_money}}</span>
							</template>
						</el-table-column>
						<el-table-column label="可用金额" align="center">
							<template slot-scope="scope">
								<span class="">{{scope.row.qr_enable_money}}</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="textCenter">
					<el-button size="mini" type="primary" @click="sure()" v-if="dialogType !='qr'">确定</el-button>
					<el-button size="mini" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				tableData: [],
				height: 0,
				loading: false,
				disLoading: false,
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				state: '',
				stateArr: [{
						key: '',
						val: "全部"
					},
					{
						key: 0,
						val: "停用"
					},
					{
						key: 1,
						val: "启用"
					},
					
				],
				payTypeInfoData: {},
				pay_type: 77,
				item: {},
				storeInfo: {},
				dialogTitle:"",
				dialogType:'',
				dialogVisible:false,
				dialogloading:false,
				closeModal:false,//是否点击弹框 关闭
			};
		},
		created() {
			this.payTypeInfo()
			this.storeList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			payTypeInfo(){
				let data = {pay_type:this.pay_type}
				this.$api.payTypeInfo(data).then(res=>{
					if(res.status == 1){
						this.payTypeInfoData = res.data
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			getData() {
				this.storeList()
			},
			storeList() {
				let data = {
					page: this.page,
					size: this.pageSize,
					pay_type: this.pay_type,
					state: this.state,
				}
				this.disLoading = true
				this.$api.storeList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.page = 1
				this.storeList()
			},
			handleCurrentChange(val) {
				this.page = val
				this.storeList()
			},
			showInfo(item) {
				this.dialogType = 'qr'
				this.dialogTitle = '码详情'
				this.item = item
				this.getStoreInfo()
				this.dialogVisible = true
			},
			edit(item){
				this.dialogType = 'show'
				this.dialogTitle = '编辑'
				this.item = item
				this.getStoreInfo()
				this.dialogVisible = true
			},
			newStore(dialogType){
				this.dialogType = 'new'
				this.dialogTitle = '新增'
				this.dialogVisible = true
				this.storeInfo={
					store_name:'',
					state:0
				}
			},
			
			getStoreInfo() {
				let data = {
					id: this.item.id
				}
				this.$api.storeInfo(data).then(res => {
					if (res.status == 1) {
						this.storeInfo = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			close(){
				this.dialogVisible = false
				this.dialogloading = false
				this.dialogTitle = ''
				this.dialogType=""
				this.item = {}
				this.storeInfo={}
				
			},
			
			sure(){
				if(this.dialogType == 'show'){
					if (isEmpty(this.storeInfo.store_name, "请输入姓名")) {
						return
					}					
					if (isEmpty(this.storeInfo.state, "请选择状态")) {
						return
					}
					let data = {
						id: this.storeInfo.id,
						store_name: this.storeInfo.store_name,
						state: this.storeInfo.state,
					
					}
					this.$api.editStore(data).then(res=>{
						if(res.status == 1){
							this.close()
							this.storeList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				if(this.dialogType == 'new'){
					if (isEmpty(this.storeInfo.store_name, "请输入姓名")) {
						return
					}
					if (isEmpty(this.storeInfo.state, "请选择市")) {
						return
					}
					let data = {
						store_name: this.storeInfo.store_name,
						state: this.storeInfo.state,
						
					}
					this.$api.addStore(data).then(res=>{
						if(res.status == 1){
							this.close()
							this.storeList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
			},
			gotopage(type,data,type2,data2){
				if(type){
					if(type2){
						let queryData = {}
						queryData['id'] = this.item.id
						queryData[type] = data 
						queryData[type2] = data2
						this.$router.push({
							path: '/passageAlisub',
							query: queryData,
						})
					}else{
						let queryData = {}
						queryData['id'] = this.item.id
						queryData[type] = data 
						this.$router.push({
							path: '/passageAlisub',
							query: queryData,
						})
					}
				}else{
					let queryData = {}
					queryData['id'] = this.item.id
					this.$router.push({
						path: '/passageAlisub',
						query: queryData,
					})
				}
			}

		},
		watch: {
			
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
				})
			}
		},

	};
</script>

<style scoped="scoped">
	.dataKey{
		display: inline-block;
		flex: 0 0 100px;
		text-align: left;
	}
</style>
